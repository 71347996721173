<template>
  <div class="d-flex my-2">
    <div class="d-flex flex-grow-1 mx-n1">
      <div
        class="px-1"
        style="width:40%"
      >
        <Select
          field="field"
          label="Field"
          required
          :items="fields"
          :value="value.field"
          @change="change"
        />
      </div>
      <div
        class="px-1"
        style="width:30%"
      >
        <Select
          field="opcode"
          required
          :disabled="!value.field"
          :items="opcodes"
          :value="value.opcode"
          @change="change"
        />
      </div>
      <div
        class="px-1"
        style="width:30%"
      >
        <template
          v-if="value.opcode === '~'"
        >
          <InputText
            field="value"
            :disabled="!value.opcode"
            :value="value.value"
            @change="change"
          />
        </template>
        <template
          v-if="value.opcode === 'eq' || value.opcode === 'ne' "
        >
          <Select
            v-if="!value.field.match(/Date/) && uniqueDataValues"
            field="value"
            required
            :disabled="!value.opcode"
            :items="uniqueDataValues"
            :label="value.value"
            :value="value.value"
            @change="change"
          />
          <div
            v-else
            class="mx-2"
          >
            <DatePicker
              field="value"
              :value="value.value"
              :today="true"
              @change="changeDate"
            />
          </div>
        </template>
        <template
          v-if="value.opcode === 'lt' || value.opcode === 'gt' "
        >
          <DatePicker
            field="value"
            :value="value.value"
            :today="true"
            @change="changeDate"
          />
        </template>
      </div>
    </div>
    <div
      class="px-2"
      style="width:100px"
    >
      <v-tooltip
        top
        v-if="showRemove"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="pa-0"
            color="error"
            depressed
            icon
            @click="$emit('remove', value)"
          >
            <v-icon>
              {{ mdiMinus }}
            </v-icon>
          </v-btn>
        </template>
        <span>Remove this field constraint</span>
      </v-tooltip>
      <v-tooltip
        v-if="showAdd"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="pa-0"
            color="primary"
            depressed
            icon
            :disabled="!complete"
            @click="$emit('add')"
          >
            <v-icon>
              {{ mdiPlus }}
            </v-icon>
          </v-btn>
        </template>
        <span>Add additional field constraint</span>
      </v-tooltip>
    </div>
  </div>

</template>

<style scoped>
  button.v-btn{min-width: 38px !important;}
</style>

<script>
import { mdiMinus, mdiPlus } from '@mdi/js'

import { mapGetters } from 'vuex'
import { dictionary, searchable } from '@/models/finding'

import DatePicker from '@/components/UI/date-picker'
import InputText from '@/components/UI/forms/input-text'
import Select from '@/components/UI/forms/select'

export default {
  props: {
    showAdd: {
      type: Boolean,
      default: false
    },
    showRemove: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default() { return {} }
    }
  },
  components: {
    DatePicker,
    InputText,
    Select
  },
  data() {
    return {
      field: null,
      opcode: null,
      opcodes: [],
      uniqueDataValues: [],
      mdiMinus,
      mdiPlus
    }
  },
  computed: {
    ...mapGetters('search-lists', { getFromStore: 'get' }),
    fields() {
      return searchable('longname').map(f => ({ text: f.longname, value: f.field }))
    },
    complete() {
      return this.value.field && this.value.opcode && this.value.value
    }
  },
  methods: {
    changeDate(value) {
      if(value.value){
        this.change(value)
      }
    },
    change({ field, value }) {
      this.value[field] = value.value || value

      if (field === 'field'){
        this.setOpcodes(value.value)
        this.value.opcode = null
        this.value.value = null
      }

      if (field === 'opcode'){
        this.value.value = null
      }
    },
    async getOptions(fieldname) {
      const result = await this.$store.dispatch('search-lists/get', fieldname)
      this.uniqueDataValues = result.values.filter(v => v)
    },
    setOpcodes(fieldname) {
      const { searchOperators } = dictionary[fieldname]
      this.opcodes = searchOperators
      this.getOptions(this.value.field)
    }
  },
  watch: {
    value(newVal) {
      if(!this.value.field) return
      this.setOpcodes(newVal.field)
    }
  },
  mounted() {
    if(!this.value.field) return
    this.setOpcodes(this.value.field)
  }
}
</script>
