<template>
  <Ask
    ref="ask"
    :canAccept="name && Boolean(name.trim())"
  >
    <InputText
      autofocus
      class="mb-2 mt-4"
      placeholder="Saved Search Name"
      :value="name"
      @change="change"
    />
  </Ask>
</template>

<style scoped>
  .list{
    height:320px;
    overflow-y:auto;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
</style>

<script>
import { mdiContentSave } from "@mdi/js"

import InputText from '@/components/UI/forms/input-text'
export default {

  props: {},
  components: { InputText },
  data () {
    return {
      name: ''
    }
  },
  methods: {
    change (value) {
      this.name = value.value
    },
    async open (name) {
      this.name = name

      const result = await this.$refs.ask.open(
        'Save Search',
        '',
        {
          cancelLabel: 'Cancel',
          acceptLabel: 'save',
          reverseActions: true,
          icon: mdiContentSave,
          width: 460
        }
      )

      return {
        result: result,
        name: this.name
      }
   }
  }
}
</script>
