<template>
  <v-row dense no-gutters class="" style="flex-wrap:nowrap">
    <v-col cols="12 d-flex" ref="parameters">
      <div
        v-if="query.length - 1"
        cols="auto"
        class="d-flex align-stretch justify-start flex-grow-1"
        style="position: relative;max-width:160px;"
      >
        <div
          class="select-wrapper"
        >
          <Select
            field="field"
            required
            :items="['AND', 'OR']"
            :value="mode"
            @change="changeMode"
          />
        </div>
        <div class="svg-wrapper d-none d-md-block px-3" style="max-width:60px">
          <svg
            preserveAspectRatio="none"
            viewBox="0 0 10 10"
          >
            <path
              stroke="#0088cb"
              stroke-opacity="0.5"
              vector-effect="non-scaling-stroke"
              stroke-width="1.5px"
              fill="transparent"
              :d="svgPath"
            />
          </svg>
        </div>
      </div>
      <div class="flex-grow-1">
        <Parameter
          v-for="(parameter, i) in query"
          :key="i"
          :showAdd="i === query.length - 1"
          :showRemove="(query.length > 1)"
          :value="parameter"
          @add="add"
          @remove="remove"
        />
      </div>
      <!-- <Parameter
        @change="change('0')"
      /> -->
      <!-- {{ parameters }} -->
    </v-col>
  </v-row>
</template>

<style scoped>
  svg{
    width: 100%;
    height: 100%;
  }
  .select-wrapper{
    align-self: center;
    position: relative;
    min-width: 98px;
  }
</style>

<script>
import Vue from 'vue'
import Select from '@/components/UI/forms/select'

import Parameter from './parameter'

export default {
  props: {
    mode: {
      type: String,
      default: ''
    },
    query: {
      type: Array,
      default() { return {} }
    }
  },
  components: { Parameter, Select },
  data() {
    return {
      parameters: [],
      parametersHeight: 0
    }
  },
  computed: {
    svgPath() {
      const h = this.parametersHeight

      if(!h) return

      const l = this.query.length
      const f = 10 / h
      const p = 30 * f
      const o = (10 - (2 * p)) / (l - 1)
      const t = (10*f)
      const path = []
      for (let i = 0; i < l; i ++){
        const y = p + (o * i)
        if (!i) {
          path.push(`M5 ${y+(t)} L5 ${5-t}`)
          path.push(`M5 ${5+t} L5 ${10-p-t}`)
          path.push(`M0 5 L2.5 5`)
          path.push(`M2.5 5 A2.5 ${t} 0 0 0 5 ${5-(t)}`)
          path.push(`M2.5 5 A2.5 ${t} 0 0 1 5 ${5+(t)}`)
          path.push(`M10 ${y} L7.5 ${y} A2.5 ${t} 0 0 0 5 ${y+(t)}`)
        }
        if (i === l - 1) {
          path.push(`M10 ${y} L7.5 ${y} A2.5 ${t} 0 0 1 5 ${y-(t)}`)
        }
      }
      // mirrored version
      // for (let i = 0; i < l; i ++){
      //   const y = p + (o * i)
      //   if (!i) {
      //     path.push(`M5 ${y+(t)} L5 ${5-t}`)
      //     path.push(`M5 ${5+t} L5 ${10-p-t}`)
      //     path.push(`M10 5 L7.5 5`)
      //     path.push(`M7.5 5 A2.5 ${t} 0 0 1 5 ${5-(t)}`)
      //     path.push(`M7.5 5 A2.5 ${t} 0 0 0 5 ${5+(t)}`)
      //     path.push(`M0 ${y} L2.5 ${y} A2.5 ${t} 0 0 1 5 ${y+(t)}`)
      //   }
      //   if (i === l - 1) {
      //     path.push(`M0 ${y} L2.5 ${y} A2.5 ${t} 0 0 0 5 ${y-(t)}`)
      //   }
      // }
      return path.join(' ')
    }
  },
  methods: {
    changeMode(value) {
      this.$emit('changeMode', value)
    },
    add() {
      this.query.push({ field: null, opcode: null, value: null})
    },
    remove(param){
      const i = this.query.indexOf(param)
      this.query.splice(i, 1)
    }
  },
  watch: {
    query() {
      Vue.nextTick().then(() => {
        this.parametersHeight = this.$refs.parameters.clientHeight
      })
    }
  }
}
</script>
