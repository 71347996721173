<template>
  <v-row dense>
    <v-col cols="12">
      <div
        class="columns"
      >
        <template
          v-for="(field, i) in checkboxes"
        >
          <v-checkbox
            v-model="field.value"
            :key="i"
            class="cbx my-0 pa-0"
            color="primary"
            dense
            hide-details
            :ripple="false"
            :label="field.label"
            @change="change"
          >
            <template v-slot:label>
              <div class="label">{{ field.label }}</div>
            </template>
          </v-checkbox>
        </template>
      </div>
    </v-col>
  </v-row>
</template>

<style scoped>
  .columns{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height:170px;
    /*column-count: 5;
    column-fill: auto;*/
  }
  .cbx .label{
    width:auto;
    max-width: 200px;
    min-width: 80%;
    /*background: red;*/
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
<style>
  .cbx .v-input__slot{
    align-items: flex-start;
  }
  .cbx .v-input__slot .v-label{
    padding-top:2px;
  }
</style>

<script>
import { listable } from '@/models/finding'

export default {
  props: {
    columns: {
      type: Array,
      default () { return []}
    }
  },
  computed: {
    checkboxes () {
      return listable('longname').map(f => ({
        field: f.field,
        label: f.longname,
        value: this.columns.indexOf(f.field) !== -1
      }))
    }
  },
  methods: {
    change() {
      const columns = this.checkboxes.reduce((a, f) => f.value ? [...a, f.field] : a, [])
      this.$emit('change', columns)
    }
  }
}
</script>
