<template>
  <h1 v-if="!enabled">
    Advanced Search
  </h1>
  <v-container v-else fluid class="px-4 pb-16">

    <LoadDialog ref="loadDialog" />
    <SaveDialog ref="saveDialog"/>

    <v-row dense no-gutters>
      <v-col
        align-self="center"
        cols="1"
      >
        <label class="primary--text"> Query: </label>
      </v-col>
      <v-col cols="9">
        <Query
          :query="query"
          :mode="mode"
          @changeMode="changeMode"
        />
      </v-col>
      <v-col
        cols="2 mt-2 nowrap d-flex"
        align-self="start"
      >
        <v-spacer></v-spacer>
        <v-menu
          offset-y
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              depressed
              v-bind="attrs"
              v-on="on"
            >
              Actions
              <v-icon right>
                {{ mdiMenuDown }}
              </v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              key="0"
              link
              @click="load"
            >
              <v-list-item-icon
                dense
                class="ml-0 mr-4"
              >
                <v-icon>
                  {{ mdiFolderOpenOutline }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Load Search</v-list-item-title>
            </v-list-item>
            <v-list-item
              key="1"
              link
              :disabled="!query[0].value"
              @click="save"
            >
              <v-list-item-icon
                dense
                class="ml-0 mr-4"
              >
                <v-icon :disabled="!query[0].value">
                  {{ mdiContentSaveOutline }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Save Search</v-list-item-title>
            </v-list-item>
            <v-list-item
              key="2"
              link
              :disabled="!query[0].value"
              @click="saveas"
            >
              <v-list-item-icon
                dense
                class="ml-0 mr-4"
              >
                <v-icon :disabled="!query[0].value">
                  {{ mdiContentSaveAllOutline }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Save Search As...</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="1 mt-4">
        <label class="primary--text"> Columns: </label>
      </v-col>
      <v-col cols="11 mt-3" style="position:relative">
        <ColumnList
          :columns="columns"
          @change="changeColumns"
        />
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col>
        <Results
          :columns="columns"
          :query="query"
          :mode="mode"
          :label="savedSearch.name"
          @clear="clear"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mdiMenuDown,
  mdiFolderOpenOutline,
  mdiContentSaveOutline,
  mdiContentSaveAllOutline
} from '@mdi/js'

import ColumnList from './columns'
import Query from './query'
import Results from './results'

import LoadDialog from './load-search-dialog.vue'
import SaveDialog from './save-search-dialog.vue'

export default {
  components: {
    ColumnList,
    LoadDialog,
    Query,
    Results,
    SaveDialog
  },
  created () {
    this.clear()
  },
  data () {
    return {
      savedSearch: null,
      total: 0,
      enabled: true,
      mdiMenuDown,
      mdiFolderOpenOutline,
      mdiContentSaveOutline,
      mdiContentSaveAllOutline
    }
  },
  computed: {
    columns: {
      get(){
        return this.savedSearch.columns
      },
      set (value){
        return this.savedSearch.columns = value
      }
    },
    query() {
      return this.savedSearch.query
    },
    mode() {
      return this.savedSearch.mode
    }
  },
  methods: {
    clear() {
      const { SavedSearch } = this.$FeathersVuex.api
      this.savedSearch = new SavedSearch()
    },
    changeMode ({value}) {
      this.savedSearch.mode = value
    },
    changeColumns (value) {
      this.columns = value
    },
    async load () {
      const { result, selected } = await this.$refs.loadDialog.open()
      if (result) {
        const ss = await this.$store.dispatch('saved-searches/get', selected._id)
        setTimeout(() => {
          this.savedSearch = ss
        }, 300)
      }
    },
    async save () {
      if ( this.savedSearch._id ) {
        return this.savedSearch.save()
      } else {
        const { result, name } = await this.$refs.saveDialog.open()
        if (result) {
          this.savedSearch.name = name
          this.savedSearch.create()
        }
      }
    },
    async saveas () {
      const newName = `${this.savedSearch.name} - copy`
      const { result, name } = await this.$refs.saveDialog.open(newName)
      if (result) {
        const { SavedSearch } = this.$FeathersVuex.api
        const newSearch = new SavedSearch()
        newSearch.mode = this.savedSearch.mode
        newSearch.name = name
        newSearch.query = [...this.savedSearch.query]
        newSearch.create()
        this.savedSearch = newSearch
      }
    }
  }

}
</script>
