<template>
  <v-row dense no-gutters>

    <v-col cols="12">
      <v-divider class="my-3"/>
    </v-col>

    <v-col
      v-if="hasQuery"
      cols="12 mb-2 px-0"
      class="d-flex align-center justify-space-between"
    >
      <div class="d-flex align-center text-h5">
        <v-chip
          color="success"
          class="ma-0 mr-2 px-3"
        >
          <div class="text-h5">{{ total }}</div>
        </v-chip>
        Results for {{ label || 'unsaved search'}}
      </div>
      <div
        class=""
      >
        <v-btn
          color="error"
          text
          @click="$emit('clear')"
        >
          Clear Query
        </v-btn>
        <v-btn
          class="ml-2"
          color="success"
          depressed
          :disabled="!total"
          :loading="downloading"
          @click="download"
        >
          Download Results
        </v-btn>
      </div>
    </v-col>

    <div v-else>No Results</div>

    <v-col
      cols="12"
      v-if="hasQuery"
    >
      <!-- {{ query }} -->
      <DataTable
        :cols="cols"
        :data="items"
        @selectOne="view"
        @sort="sort"
        @filter="filter"
      >
        <!-- <template v-slot:item.acbAuditType="{ item }">
          {{ item }}
        </template> -->
      </DataTable>

      <v-divider class="mb-3" />

      <FeathersVuexPagination v-model="pagination" :latest-query="latestQuery">
        <template #default="{ currentPage, pageCount, toStart, toEnd, toPage, next, prev, canNext, canPrev }">
          <div
            v-if="pageCount > 1"
            class="d-flex justify-space-between"
          >
            <div class="d-flex align-center primary--text text-subtitle-1 ">
              <div class="px-2 mr-3">{{ paginationString }}</div>
              <v-btn
                class="px-2"
                text
                small
                color="primary"
                :disabled="total > showAllThreshold"
                @click="showAll"
              ><span class="text-subtitle-1" style="text-transform: none">Show All Results</span></v-btn>
            </div>
            <Pagination
              v-model="currentPage"
              :length="pageCount"
              :total-visible="9"
              @input="toPage"
            />
          </div>
        </template>
      </FeathersVuexPagination>
    </v-col>

    <v-col
      v-if="!columns.length"
      cols="12"
    >
      <v-alert
        class="mt-3"
        dense
        text
        type="info"
      >
        Please select at least one output column.
      </v-alert>
    </v-col>
    <FindingOverlay
      idkey="advanced-search"
      :finding="selected"
      @close="selected = null"
    />
  </v-row>
</template>

<script>
import { useFind, useGet } from 'feathers-vuex'
import { computed, ref, watch } from '@vue/composition-api'
import { dictionary } from '@/models/finding'

import FindingOverlay from '@/components/shared/finding-overlay'
import Pagination from '@/components/UI/pagination'

export default {
  components: {
    FindingOverlay,
    Pagination,
  },
  props: {
    columns: {
      type: Array,
      default () { return [] }
    },
    label: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: ''
    },
    query: {
      type: Array,
      default () { return [] }
    }
  },
  setup(props, context) {
    const { Finding } = context.root.$FeathersVuex.api
    const { $options, $store } = context.root
    const user = $store.state.auth.user
    const defaultLimit = 100
    const maxLimit = 100000

    const acUserSelectedCS = computed (() => $store.state.acUserSelectedCS)

    const downloading = ref(false)

    const selected = ref(null)
    const limit = ref(defaultLimit)
    const showAllThreshold = 1000

    const pagination = ref({
      $limit: limit,
      $skip: 0
    })

    watch(
      () => props.query,
      () => {
        // reset when the query changes
        pagination.value.$limit = defaultLimit
        pagination.value.$skip = 0
      }
    )

    watch(
      () => props.columns,
      () => {
        // reset when the columns changes
        pagination.value.$limit = defaultLimit
        pagination.value.$skip = 0
      }
    )

    watch(
      () => props.mode,
      () => {
        // reset when the mode changes
        pagination.value.$limit = defaultLimit
        pagination.value.$skip = 0
      }
    )

    const $filter = ref({})

    const $sort = ref({ acbDate: -1 })

    const classClause = computed(() => {
      if (user.type === 'ac') {
        // need this to filter the local store
        // when an AC switches CS
        let cls = user.assignedTo[acUserSelectedCS.value].value
        if (cls === 'DNV') {
          cls = { $in: ['DNV', 'DNV-GL'] }
        }
        return { acbClass: cls }
      } else if (user.type === 'cs') {
        // need this to filter the local store
        // when any record is updated elsewhere
        let cls = user.cs.value
        return { acbClass: cls }
      } else {
        return null
      }
    })

    const flagStateClause = computed(() => {
      if (user.type === 'fs') {
        // need this to filter the local store
        // when any record is updated elsewhere
        let flg = user.fs.value
        return { acbFlag: flg }
      } else {
        return null
      }
    })

    const params = computed(() => {

      if (!props.query[0].value) {
        return false
      }

      if (!props.columns.length) {
        return false
      }

      let select = [...props.columns]

      // also need to select all query fields
      // else the mongoose query won't return results :/
      const queryFields = props.query.reduce((accumulator, parameter) => ([
          ...accumulator,
          parameter.field
        ]), [])

      select = [...select, ...queryFields]

      const escapeRegExp = string => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      }

      // need to convert acbRemote from Yes/No to true/false
      const fix = (field, value) => {
        return field === 'acbRemote'
                ? value === 'Yes'
                : value
      }

      const q1 = props.query.reduce((accumulator, { field, opcode, value }) => ([
        ...accumulator,
        {
          // handle opcodes in a tertiary statement
          [field]: opcode === 'eq' ? fix(field, value)
                 : opcode === '~'  ? { $regex : escapeRegExp(value), $options: 'i' }
                 : opcode === 'lt' ? { $lt : value }
                 : opcode === 'gt' ? { $gt : value }
                 : opcode === 'ne' ? { $ne : value }
                 : value
        }
      ]), [])

      // need to convert acbRemote from Yes/No to true/false
      const filter = { ...$filter.value }
      if ('acbRemote' in filter) {
        filter.acbRemote = ( filter.acbRemote === 'Yes' )
      }

      const query = {
        $select: select,
        $sort: $sort.value,
        ...pagination.value,
        ...classClause.value,
        ...flagStateClause.value,
        ...filter,
      }

      const mode = props.mode

      if(mode === 'AND'){
        query.$and = q1
      }

      if(mode === 'OR'){
        query.$or = q1
      }

      return {
        qid: 'advancedSearch',
        paginate: true,
        query
      }

    })

    const { items, latestQuery, paginationData } = useFind({
      model: Finding,
      params,
      fetchParams: params
    })

    const total = computed(() => {
      return ((paginationData.value.advancedSearch || {}).mostRecent || {}).total
    })

    const paginationString = computed(() =>{
      const t = total.value
      const r = $options.filters.pluralize('Result', t)
      const l = latestQuery.value && latestQuery.value.response ? latestQuery.value.response.limit : 0
      const s = latestQuery.value && latestQuery.value.response ? latestQuery.value.response.skip : 0
      return `${s} - ${Math.min(s+l, t)} of ${t} ${r}`
    })

    const hasQuery = computed(() => {
      return props.query[0].value
    })

    const cols = computed(() => {
      return props.columns.sort().map(f => {
        const df = dictionary[f]
        return {
          ...df,
          label: df.shortname,
          width: '1%',
        }
      }).sort((a, b) => a.label > b.label ? 1 : -1)
    })

    const download = async () => {
      downloading.value = true
      const query = {
        ...latestQuery.value.query,
        ...classClause.value,
        $skip: 0,
        $limit: maxLimit,
      }
      // needed at the server side
      if (user.type === 'ac') {
        query.$cs = $store.state.acUserSelectedCS
      }
      $store.dispatch('getXLSXDoc', { query }).then(() => {
        downloading.value = false
      })
    }

    const filter = value => {
      $filter.value = { ...value }
    }

    const sort = value => {
      $sort.value = { ...value }
    }

    // const view = ( item ) => {

    //   selected.value = item
    //   // ddo.value = true
    // }

    const view = async i => {
      const { item } = await useGet({
        model: Finding,
        id: i._id
      })
      selected.value = item.value
    }

    const showAll = () => {
      limit.value = maxLimit
      pagination.value.$limit = maxLimit
    }

    return {
      // actionMenuItems,
      cols,
      // ddo,
      // ddt,
      download,
      downloading,
      filter,
      hasQuery,
      items,
      latestQuery,
      limit,
      pagination,
      paginationString,
      params,
      selected,
      showAll,
      showAllThreshold,
      sort,
      total,
      view
    }
  }
}
</script>
