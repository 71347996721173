<template>
  <div
    v-if="label === '...'"
    class="mx-1 py-4 px-0 ellipsis primary--text"
  >
    ...
  </div>
  <v-btn
    v-else
    class="mx-1 py-4 px-0 pagination-button"
    depressed
    x-small
    :color="active ? 'primary' : 'white'"
    :ripple="false"
    @click="$emit('nav')"
  >
    <v-icon
      v-if="icon"
      color="primary"
    >{{ icon }}</v-icon>
    <div
      v-else
      :class="{
        'primary--text': !active,
        'text-subtitle-1': true
      }"
    >{{ label }}</div>
  </v-btn>
</template>


<style scoped>
  .pagination-button, .ellipsis{
    align-items: center;
    display: inline-flex;
    justify-content: center;
    line-height: 0;
    min-height: 32px !important;
    min-width: 32px !important;
  }
  .ellipsis{
    pointer-events: none;
    user-select: none;
  }
</style>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    label: {
      type: [String, Number],
      default: '!'
    },
  }
}
</script>
