<template>
  <nav role="navigation">
    <v-item-group
      v-model="page"
      class="d-flex align-center"
      mandatory
    >
      <v-item>
        <Btn
          :icon="mdiChevronLeft"
          @nav="prev"
        />
      </v-item>
      <v-item
        v-for="(n, i) in buttons"
        v-slot="{ active, toggle }"
        :key="i"
        :value="n"
      >
        <Btn
          :label="n"
          :active="active"
          @nav="toggle"
        />
      </v-item>
      <v-item>
        <Btn
          :icon="mdiChevronRight"
          @nav="next"
        />
      </v-item>
    </v-item-group>
  </nav>
</template>

<script>
import {
  mdiChevronLeft,
  mdiChevronRight
} from "@mdi/js"

import Btn from "./button"

export default {
  components: { Btn },
  props: {
    length: {
      type: Number,
      default: 4
    },
    totalVisible: {
      type: Number,
      default: 4
    },
    value: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    mdiChevronLeft,
    mdiChevronRight
  }),
  computed: {
    page: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    buttons() {
      return this.pagination(this.page, this.length)
    }
  },
  methods: {
    prev() {
      this.$emit('input', this.value - 1)
    },
    next() {
      this.$emit('input', this.value + 1)
    },
    getRange(start, end) {
      return Array(end - start + 1)
        .fill()
        .map((v, i) => i + start)
    },
    pagination (currentPage, pageCount) {
      let lim = Math.max(this.totalVisible, 5)
      let delta
      if (pageCount <= lim) {
        // delta === 7: [1 2 3 4 5 6 7]
        delta = lim
      } else {
        const a = Math.ceil(lim/2)
        const b = a - 1
        const c = lim - 5
        const d = c + 2
        delta = (currentPage > a && currentPage < pageCount - b) ? c : d
      }

      const range = {
        start: Math.floor(currentPage - delta / 2),
        end: Math.floor(currentPage + delta / 2)
      }

      if (range.start - 1 === 1 || range.end + 1 === pageCount) {
        range.start += 1
        range.end += 1
      }

      let pages =
        currentPage > delta
          ? this.getRange(Math.min(range.start, pageCount - delta), Math.min(range.end, pageCount))
          : this.getRange(1, Math.min(pageCount, delta + 1))

      const withDots = (value, pair) => (pages.length + 1 !== pageCount ? pair : [value])

      if (pages[0] !== 1) {
        pages = withDots(1, [1, '...']).concat(pages)
      }

      if (pages[pages.length - 1] < pageCount) {
        pages = pages.concat(withDots(pageCount, ['...', pageCount]))
      }

      return pages
    }
  }
}


</script>
